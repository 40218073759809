/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "nodeapi",
            "endpoint": "https://2ozfcafoeh.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "stripeWebhooks",
            "endpoint": "https://eo25kk2rji.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:17fa03a6-e777-42bc-8dfa-c6f580da53f7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FTvrret3L",
    "aws_user_pools_web_client_id": "2calu4g0mhmr8g0qmdoms9to92",
    "oauth": {
        "domain": "bettercodeai-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://bettercodeai.com/",
        "redirectSignOut": "http://localhost:3000/,https://bettercodeai.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "opentelemetrygpt-20230313081620-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1hmjf6x6pz6kq.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "contextStore-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "subscriptions-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "usage-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "user-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
